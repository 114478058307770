.subHeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 2rem 1.5rem 3rem;
}

.subHeaderButtonsArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.3rem;
}

@media (max-width: 1200px) {
  .subHeaderRow {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }
}

@media (max-width: 768px) {
  .subHeaderRow {
    align-items: center;
  }

  .subHeaderButtonsArea {
    flex-direction: column;
  }
}

@media (max-width: 468px) {
  .subHeaderTabsComponentArea {
    display: none;
  }
}
