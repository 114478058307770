.selectBasicInfoContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 1.5rem;
}

.checkbox-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.0015em;
  padding-bottom: 1rem;
}
.radio-wrapper {
  box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
  flex-basis: 374px;
  flex-grow: 1;
  min-width: 0;
  display: flex;
  align-items: center;
}
.radio-group {
  gap: 3rem;
}

.radio-wrapper-icon {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 1rem;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .radio-group {
    gap: 1rem;
    flex-wrap: wrap !important;
  }
  .checkbox-title {
    padding: 0.5rem;
  }
}
