.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  min-width: 65px;
  background-color: var(--primary);
  color: var(--backgroundPrimary);
  cursor: pointer;
}

.dropdownArrow {
  color: var(--primary);
}

.dropdownText {
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--title);
}
.titleText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1.5rem;
  height: 100%;
  width: 100%;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 140% !important;
  color: var(--title) !important;
}

@media (max-width: 480px) {
  .sidebar {
    position: absolute;
  }
}
