.mainContentArea {
  background-color: #f8f7fa;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.calendarComponentArea {
  width: 90%;
  height: 100%;
  margin-top: 1.5rem;
  display: flex;
  align-self: center;
}

.footerText {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 26px;
}
