.templateCardGridsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--backgroundSecondary);
}

.templateCardGridsTextAndButtonArea {
  display: flex;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 1.5rem;
}

.templateCardGridsText {
  display: flex;
  margin-right: auto;
  font-weight: 600;
  font-size: 20px;
  color: var(--primary);
}

.templateCardGridsButon {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 20px !important;
  text-transform: none !important;
  gap: 8px;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
}

.templateCardGridsArea {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
  padding-left: 24px;
}

.templateCardGridsCard {
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  box-sizing: border-box;
}

.paginationComponentArea {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  gap: 20px;
  position: relative;
}

.paginationAlertBox {
  border-radius: 8px 8px 8px 8px;
  width: 318px;
  height: 48px;
  padding: 6px 16px;
  background: var(--paginationAlert);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12);
  color: var(--backgroundPrimary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  margin-left: 800px;
}

.paginationAlertSpanElement {
  font-weight: 600;
  padding-left: 4px;
  padding-right: 4px;
}
