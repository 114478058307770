.globalSearchFilterContainer {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}

.globalSearchFilterMainText {
  font-style: normal;
  font-weight: 600;
  font-size: calc(7px + 0.5vw);
  line-height: 24px;
  padding-left: 0.5rem;
  color: var(--description);
}

.globalSearchFilterCheckboxArea {
  display: flex;
  padding-left: 3rem;
}

@media (max-width: 1020px) {
  .globalSearchFilterContainer {
    flex-direction: column;
    padding-bottom: 18rem;
  }

  .globalSearchFilterMainText {
    padding-top: 1.5rem;
  }

  .globalSearchFilterCheckboxArea {
    flex-direction: column;
  }
}
