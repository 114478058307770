.allTemplatesMainContentArea {
  background-color: var(--backgroundSecondary);
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.allTemplatesTextandButtonsArea {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  width: 100%;
}

.allTemplatesTextArea {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
}

.allTemplatesSectionMainText {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary);
}

.allTemplatesSectionSmallText {
  padding-top: 1rem;
  color: var(--filterText);
  font-weight: 400;
  font-size: 12px;
}

.allTemplatesButtonArea {
  display: flex;
  margin-left: auto;
  padding-right: 2.5rem;
  align-items: center;
  gap: 20px;
}

.allTemplatesFilterArea {
  width: 100%;
  background-color: var(--filterBackground);
  padding: 2.5rem 2.5rem 2.5rem 3rem;
  box-sizing: border-box;
}

.allTemplatesFilterText {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: var(--description);
  padding-bottom: 1.5rem;
}

.allTemplatesFooterText {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 26px;
}

@media (max-width: 1024px) {
  .allTemplatesTextandButtonsArea {
    flex-direction: column;
  }

  .allTemplatesTextArea {
    width: 100%;
    align-items: center;
    padding-left: 0;
  }

  .allTemplatesButtonArea {
    width: 100%;
    flex-direction: column;
    padding-top: 3rem;
    padding-right: 0;
  }
}
