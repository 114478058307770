.checklistComponentMainContainer {
  padding: 1.2rem;
  height: fit-content;
  width: 310px;
  background-color: var(--backgroundPrimary);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.checklistComponentMainText {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  padding-bottom: 0.5rem;
  padding-left: 1.4rem;
}

.checklistComponentSectionsContainer {
  display: flex;
  height: 100%;
  position: relative;
  padding-left: 1.5rem;
}

.checklistComponentSelectorArea {
  height: 97%;
  width: 4px;
  background-color: var(--backgroundSecondary);
  position: absolute;
  background: var(--backgroundSecondary);
  overflow-y: hidden;
}

.checklistComponentSelector {
  position: absolute;
  width: 4px;
  color: var(--primary);
  top: 0;
  border-left: 4px solid var(--primary);
}

.checklistComponentContentArea {
  padding-left: 20px;
  width: 235px;
}

.checklistComponentPublishButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  background: var(--primary);
  color: var(--backgroundPrimary);
  padding: 8px 16px 9px;
  gap: 8px;
  width: 100%;
  height: 57px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  border: none;
}

.checklistLastStepBefore {
  width: 80%;
}

.checklistLastStepAfter {
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  align-items: center;
  width: 100%;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  height: 40px;
  font-weight: 700;
  color: var(--primary);
  background-color: var(--checklist);
  border-radius: 5px;
}
