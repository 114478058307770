.gatheringListCardContainer {
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.gatheringListCardDateAndTimeArea,
.gatheringListCardImageAndTextArea,
.gatheringListCardButtonArea,
.gatheringListCardArchiveArea {
  display: flex;
  flex-direction: column;
}

.gatheringListCardDateAndTimeArea {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.gatheringListCardImageAndTextArea {
  width: 25%;
}

.gatheringListCardImage {
  width: 246px;
  height: 112px;
  border-radius: 8px;
  object-fit: cover;
}

.gatheringListCardDate,
.gatheringListCardTime {
  color: var(--description);
}

.gatheringListCardText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.001em;
  color: var(--title);
  padding-top: 16px;
}

.gatheringListCardButtonArea {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 2.5rem;
  padding-left: 3rem;
}

.gatheringListCardButton {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  border-radius: 20px !important;
  text-transform: none !important;
  gap: 8px;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
}

.gatheringListCardArchiveArea {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 2.5rem;
}

.gatheringListCardArchiveButton {
  cursor: pointer;
  padding-left: 1rem;
}

@media only screen and (max-width: 1024px) {
  .gatheringListCardContainer {
    flex-direction: column;
    justify-content: center;
  }

  .gatheringListCardDateAndTimeArea {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 1.5rem;
  }

  .gatheringListCardImageAndTextArea {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .gatheringListCardButtonArea {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .gatheringListCardArchiveArea {
    width: 100%;
    align-items: center;
  }
  .gatheringListCardArchiveButton {
    padding-left: 0;
  }
}
