.container {
  height: 24px;
  width: 203px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.buttonContainer {
  height: 24px;
  width: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.titleText {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  color: rgba(34, 51, 90, 0.5);
  cursor: default;
}
.avatarContainer {
  height: 24px !important;
  width: 24px !important;
  cursor: pointer;
}
