.profileComponentNotificationBadge {
  position: absolute;
  display: flex;
  background-color: var(--notificationBadge);
  border-radius: 20px;
  width: 25px;
  height: 25px;
  top: 0px;
  right: 6px;
  color: var(--backgroundPrimary);
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  align-items: center;
  justify-content: center;
}

.profileComponentNotificationBadgeOverflow {
  position: absolute;
  display: flex;
  background-color: var(--description);
  border-radius: 20px;
  width: 15px;
  height: 15px;
  top: 5px;
  right: 21px;
  color: var(--backgroundPrimary);
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  align-items: center;
  justify-content: center;
}
