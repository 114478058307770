:root {
  --main-text-color: #22335a;
  --main-bg-color: #4519cc;
  --secondary-text-color: #f8f7fa;
  --white-background-color: white;
}

.rbc-header > span {
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
}
.rbc-event {
  border: 1px solid #3bb3e7 !important;
  border-radius: 24px !important;
}

.legendListContainer {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 3%;
  box-sizing: content-box;
  justify-content: center;
}
.legendItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.legendItem {
  height: 20px;
  width: 20px;
  border-radius: 2px;
}

.eventTitleRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.rbc-event > .rbc-event-content {
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: var(--main-text-color);
}
.rbc-event-label {
  color: var(--main-text-color) !important;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  padding: 2px 2px !important;
}
.rbc-date-cell > button {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

.rbc-toolbar {
  justify-content: unset !important;
  gap: 3px;
}

.rbc-show-more {
  width: 100% !important;
}

.rbc-now.rbc-current > button {
  background: var(--main-bg-color);
  border-radius: 24px;
  color: var(--white-background-color);
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.rbc-toolbar-label {
  text-align: left !important;
  font-weight: 200 !important;
  font-size: 18px !important;
}
.rbc-toolbar {
  padding: 5px;
  margin-bottom: 0 !important;
}

.rbc-off-range-bg {
  background: var(--white-background-color) !important;
  color: var(--white-background-color) !important;
}

.rbc-today {
  background: var(--white-background-color) !important;
}

.rbc-header {
  background: var(--secondary-text-color) !important;
  font-weight: 200 !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 140% !important;
  color: #586a84 !important;
}

.rbc-month-header {
  height: 42px !important;
}
.rbc-month-row {
  overflow: visible !important;
}
.titleTextCalendar {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 140% !important;
}
