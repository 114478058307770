.App {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-height: 100vh;
  background-color: white;
  width: 100%;
}

:root {
  --primary: #4519cc;
  --title: #22335a;
  --description: #38476b;
  --backgroundPrimary: #ffffff;
  --backgroundSecondary: #f8f7fa;
  --backgroundAccordion: #fefeff;
  --notificationBadge: #dc5050;
  --filterBackground: #fdfdfd;
  --filterText: #4e5c7b;
  --paginationAlert: #323232;
  --checklist: #f6f4fd;
  --checklistDisabled: #9097ac;
  --checklistDisabledBorder: #edeced;
  --checklistCompleteBadgeBackground: #e6fbf1;
  --checklistCompleteBadgeColor: #20c374;
  --divider: #d9d7d7;
  --singleTab: #586482;
  --tabSelected: #8d95aa;
  --loginPrimary: #000000de;
  --tableRowPrimary: #f6f3f9;
  --tableRowSecondary: #f8f7fa;
  --tableHover: #eb9e9e;
  --dateNumber: #0f2552;
  --filterColor: #4e5c7b;
  --sidebarBackground: #f9f9f9;
  --basicInfoYesButton: #f3d16c;
  --basicInfoNoButton: #3bb3e7;
  --basicInfoGroup: #e06464;
  --filterBackground: #fdfdfd;
  --hearted: #eccf7e;
  --dashLine: #b8b8b8;
  --borderLine: #dbdbdb;
  --borderLine2: #c8ccd6;
  --borderLine3: #6083ff;
  --timestamp: #a5acb8;
  --previewDescriptionText: #757575;
  --uploadDashLine: rgba(0, 0, 0, 0.5);
  --previewBannerBackground: '#EBEBEB';
  --imageComponentBorderShadow: rgba(0, 0, 0, 0.17);
  --previewModalSubjectBanner: #edecf1;
  --uiError: #d83d3d;
  --uiWarning: #ebb30a;
}
