.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

@media (max-width: 560px) {
  .layout {
    width: calc(100vw - 65px);
    padding-left: 32px;
  }
}

@media (max-width: 480px) {
  .layout {
    width: calc(100vw - 65px);
    padding-left: 60px;
  }
}
