.MuiAccordion-root {
  box-shadow: none !important;
  border-radius: 0px !important;
  margin: 0 !important;
  padding-bottom: 1rem;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.accordionContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--backgroundAccordion);
}

.accordionFirstRow,
.accordionSecondRow {
  display: flex;
  min-height: 100px;
  width: 100%;
}

.accordiontextArea {
  display: flex;
  align-items: flex-end;
  padding-left: 3rem;
}

.leftSideText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary);
}

.accordionFirstRowButtonsArea {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: auto;
  padding-right: 1.5rem;
}

.accordionListButton {
  background: var(--primary) !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  box-shadow: none !important;
}

.accordionSecondRow {
  flex-direction: column;
}
.accordionSecondRowTabArea {
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
}

.accordionSecondRowTabText1,
.accordionSecondRowTabText2,
.accordionSecondRowTabText3,
.accordionSecondRowTabText4 {
  display: flex;
  width: 25%;
  padding-left: 3rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.001em;
  color: var(--title);
}

.accordionSecondRowTabText3 {
  padding-left: 5rem;
}

.accordionSecondRowDividerArea {
  display: flex;
  justify-content: center;
  padding-left: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}

.accordionGatheringListCardsArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 3rem;
}

.accordionFooter {
  display: flex;
  height: 100px;
  justify-content: flex-end;
  align-items: flex-end;
}

.accordionFooterButtonArea {
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  align-items: center;
}

.accordionFooterButton {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary);
  cursor: pointer;
}

.accordionCloseButton {
  text-transform: none !important;
  background-color: transparent !important;
  color: var(--primary) !important;
}

@media only screen and (max-width: 1024px) {
  .accordionFirstRow,
  .accordionSecondRow {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .accordionFirstRow {
    padding-top: 15rem;
  }

  .accordionSecondRowTabArea {
    display: none;
  }

  .accordiontextArea {
    display: flex;
    align-items: flex-end;
    padding-left: 0;
    padding-top: 3rem;
  }

  .accordionFirstRowButtonsArea {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    padding-right: 0;
  }

  .accordionGatheringListCardsArea {
    padding-left: 0;
  }

  .accordionFooter {
    justify-content: center;
    align-items: center;
    height: 50px;
  }
}
