.checklistComponentLabelText {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--title);
  letter-spacing: 0.001em;
}

.checklistComponentLabelNumberIcon {
  width: 18px;
  height: 15px;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 0.5rem;
  color: var(--backgroundPrimary);
  font-size: 10px;
  box-sizing: border-box;
}

.checklistComponentStatusBadge,
.checklistComponentStatusBadgeComplete,
.checklistComponentStatusBadgeInactive {
  font-weight: 600;
  font-size: 10px;
  line-height: 140%;
  width: 74px;
  height: 22px;
  margin-left: 1.5rem;
  border-radius: 4px;
  color: var(--primary);
  background: var(--checklist);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.checklistComponentStatusBadgeComplete {
  color: var(--checklistCompleteBadgeColor);
  background: var(--checklistCompleteBadgeBackground);
}

.checklistComponentStatusBadgeInactive {
  color: var(--checklistDisabled);
  background: 'transparent';
  border: 2px solid var(--checklistDisabledBorder);
}

.checklistComponentAccordionHeader {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  row-gap: 8px;
  height: 50px;
  overflow: hidden;
}
.checklistComponentAccordionBody {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.checklistComponentAccordionBodyItemDone,
.checklistComponentAccordionBodyItemDefault,
.checklistComponentAccordionBodyItemInProgress,
.checklistComponentAccordionBodyMissingEntry,
.checklistComponentAccordionBodyItemMissingData {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.004em;
  width: 235px;
  height: 36px;
  color: var(--description);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}
.checklistComponentAccordionBodyItemMissingData {
  font-weight: 400;
  color: var(--backgroundPrimary);
  background-color: var(--uiError);
  border-radius: 5px;
}

.checklistComponentAccordionBodyItemInProgress {
  font-weight: 700;
  color: var(--primary);
  background-color: var(--checklist);
  border-radius: 5px;
}

.checklistComponentAccordionBodyMissingEntry {
  font-weight: 400;
  color: var(--backgroundPrimary);
  background-color: var(--checklistMissingEntry);
  border-radius: 5px;
}
