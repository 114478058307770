.modalContainer {
  display: flex;
  flex-direction: column;
}

.mainModalTextArea {
  display: flex;
  justify-content: center;
  padding-top: 5rem;
}

.closeButton {
  position: absolute;
  display: flex;
  justify-content: center;
  border-radius: 30px;
  border: none;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  top: -50px;
  right: -40px;
  cursor: pointer;
}

.mobileButton {
  display: none;
}

.modalText {
  font-style: normal;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
}

.pickBoxArea {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
  padding-top: 3rem;
}

.pickBox1,
.pickBox2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  padding: 40px;
  gap: 12px;
  width: 19rem;
  height: 13rem;
}

.pickBox1 {
  background: linear-gradient(0deg, #4519cc, #4519cc);
}

.pickBox2 {
  background: #22335a;
}

.pickBoxFirstText,
.pickBoxSecondText {
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: white;
}

.pickBoxFirstText {
  font-size: 34px;
}

.pickBoxSecondText {
  font-size: 14px;
}

@media (max-width: 1020px) {
  .pickBoxArea {
    flex-direction: column;
  }

  .mainModalTextArea {
    padding-top: 2rem;
  }

  .modalText {
    font-size: 20px;
  }

  .pickBox1,
  .pickBox2 {
    width: 15rem;
    height: 7rem;
  }

  .closeButton {
    display: none;
  }

  .mobileButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    width: 100px;
    height: 50px;
    align-self: center;
  }
}
