.title {
  font-size: 20px;
  font-weight: 500;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4519cc;
  line-height: 140%;
  letter-spacing: 0.0015em;
}
.desc {
  font-size: 16px;
  font-weight: 500;
}
.desc2 {
  font-size: 16px;
  font-weight: 300;
}
