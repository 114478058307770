.experienceListCardContainer {
  display: flex;
  border-radius: 4px;
  width: 100%;
  justify-content: space-between;
  height: 162px;
}

.experienceCardImageArea {
  display: flex;
  padding-right: 2.5rem;
}

.experienceCardImage {
  width: 298px;
  height: 162px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.experienceCardImageBadgeArea {
  position: absolute;
  margin-top: 120px;
  margin-left: 10px;
}

.experienceCardImageBadge {
  background: var(--primary) !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  box-shadow: none !important;
}

.experienceCardTitleAndDescriptionArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2.5rem;
  width: 30%;
  cursor: pointer;
  padding-right: 2rem;
}

.experienceCardTitleText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--title);
  padding-bottom: 1rem;
}

.experienceCardDescriptionText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--description);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.experienceCardDateArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2.5rem;
  width: 40%;
  cursor: pointer;
}

.experienceCardAccordeonIconArea {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .experienceListCardContainer {
    flex-direction: column;
  }

  .experienceCardImageBadgeArea {
    margin-top: 100px;
    padding-right: 150px;
  }
  .experienceCardImageArea,
  .experienceCardTitleAndDescriptionArea,
  .experienceCardDateArea,
  .experienceCardAccordeonIconArea {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .experienceCardDateArea {
    flex-direction: row;
    gap: 10px;
  }

  .experienceCardTitleText {
    padding-bottom: 0;
  }

  .experienceCardAccordeonIconArea {
    padding-top: 1rem;
  }
}
