.allGatheringsScreenMainContainer {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

.allGatherinsScreenComponentArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.allGatheringsScreenRowArea2 {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}

.allGatheringsScreenTabsComponentArea {
  padding-left: 3rem;
}

.allGatheringsScreenNavbarAndViewButtonComponentArea {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  padding-right: 2rem;
  gap: 1.3rem;
}

@media (max-width: 1020px) {
  .allGatherinsScreenComponentArea {
    position: relative;
    align-items: center;
    justify-items: center;
    width: 80%;
    padding-left: 45px;
  }

  .allGatheringsScreenNavbarAndViewButtonComponentArea {
    row-gap: 20px;
    flex-direction: column;
    margin-left: 0;
    margin-left: 4rem;
  }

  .allGatheringsScreenTabsComponentArea {
    margin-top: 30px;
    padding-left: 0px;
    display: none;
  }

  .allGatheringsScreenRowArea2 {
    flex-direction: column-reverse;
  }
}
