.createFromScratchButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid rgba(34, 51, 90, 0.25);
  border-radius: 8px;
  background: var(--backgroundPrimary);
  height: 84px;
  width: 1135px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary);
  cursor: pointer;
  box-sizing: border-box;
}

.createFromScratchButtonContainer:hover {
  border: 2px solid var(--primary);
}
