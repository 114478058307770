.templateCardContainer {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  z-index: 2;
  overflow: hidden;
  background: var(--backgroundPrimary);
  border-radius: 8px;
}

.templateCardImageArea {
  display: flex;
  position: relative;
}

.templateCardImage {
  width: 350px;
  height: 230px;
  border-radius: 8px 8px 0px 0px;
}

.templateCardTextArea {
  display: flex;
  height: 94px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 8px 8px;
  position: relative;
}

.templateCardText {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: var(--title);
}

.templateCardSidebar {
  background: white;
  width: 50px;
  height: 232px;
  z-index: 1;
  top: 0;
  right: -50px;
  position: absolute;

  border-radius: 0px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  animation: 0.2s linear 0s sidebarAnimationRevert;
}

.templateCardSidebarItem {
  display: flex;
  height: 46px;
  width: 50px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.02) 16.08%, rgba(0, 0, 0, 0) 95.37%),
    var(--backgroundPrimary);
  border-bottom: 2px solid
    linear-gradient(270deg, rgba(216, 216, 216, 0) 8.73%, rgba(137, 137, 137, 0.46) 100%);
  cursor: pointer;
}

.templateCardSidebarItem:hover {
  transition: opacity 1000ms;
  transition: background 0.8s;
  background: var(--primary);
}

.templateCardSidebarItem:hover .templateCardSidebarIcon {
  color: var(--backgroundPrimary);
}

.hearted {
  color: var(--backgroundPrimary);
  display: none;
}

.templateCardSidebarIcon {
  color: var(--primary);
}

.templateCardContainer:hover {
  box-shadow: 0px 23px 33px -29px rgba(0, 0, 0, 0.09);
}

.templateCardContainer:hover .templateCardSidebar {
  right: 0;
  animation: 0.2s linear 0s sidebarAnimation;
  box-shadow: -17px 0px 14px -13px rgba(0, 0, 0, 0.22);
}

.templateCardAnimatedLine {
  position: absolute;
  height: 3px;
  border-top: 3px solid var(--primary);
  top: 0;
  left: -380px;
  width: 100%;
  animation: 0.1s linear 0s lineAnimationRevert;
}

.templateCardContainer:hover .templateCardAnimatedLine {
  left: 0;
  animation: 0.1s linear 0s lineAnimation;
}

@keyframes sidebarAnimation {
  from {
    right: -50px;
  }
  to {
    right: 0;
    transform: translateX(0);
  }
}

@keyframes sidebarAnimationRevert {
  from {
    right: 0;
  }
  to {
    right: -50px;
    transform: translateX(0);
  }
}

@keyframes lineAnimation {
  from {
    left: -350px;
  }
  to {
    left: 0;
    transform: translateX(0);
  }
}

@keyframes lineAnimationRevert {
  from {
    left: 0;
  }
  to {
    left: -350px;
    transform: translateX(0);
  }
}
