.headerComponentArea {
  display: flex;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 1rem 3rem;
}

.headerRowArea {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 150px;
}

.headerSearchComponentArea {
  display: flex;
  width: 100%;
}

.headerProfileComponentArea {
  display: flex;
  margin-top: -8px;
}

@media (max-width: 1020px) {
  .headerRowArea {
    flex-direction: column-reverse;
  }

  .headerSearchComponentArea {
    margin-top: 30px;
    width: 70%;
  }

  .headerProfileComponentArea {
    margin-right: none;
    margin-left: auto;
  }

  .headerComponentArea {
    position: relative;
    align-items: center;
    justify-items: center;
    width: 80%;
    padding-left: 45px;
  }
}

@media (max-width: 1124px) {
  .headerRowArea {
    width: 100%;
  }
  .headerComponentArea {
    width: 100%;
  }
  .headerSearchComponentArea {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .headerComponentArea {
    width: 90%;
  }
}
